<template>
  <div>
    <el-card class="box-card" shadow="never" style="margin-top: 10px">
      <TableList :tableData="tableData" :tablePagination="tablePagination" @getTableData="getAllData"
                 @activeName="activeName" :isActive="isActive"
                 :type="'my'" :categoryType="'year'"/>
    </el-card>
  </div>
</template>

<script>
import TableList from './components/tableList'
import { mapGetters } from 'vuex'


export default {
  name: 'index',
  components: { TableList },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])
  },
  data() {
    return {
      tableData: [],
      drawerVisible: false,
      rowId: 'add',
      tablePagination: {
        total: 0,
        page_size: 15,
        offset: 0,
        current_page: 1
      },
      isActive: 'my'
    }
  },
  methods: {
    activeName(val) {
      this.isActive = val
      this.getAllData()
    },
    async getAllData(val) {
      let searchCondition
      if (!val) {
        searchCondition = {
          page_size: 15,
          current_page: 1
        }
      } else searchCondition = val
      //普通询单=1 全案询单=2
      searchCondition.type=2
      let list, page_info, data
      if (this.isActive === 'all') {
        data = await this.$api.listInquiryProject(searchCondition)
      }
      if (this.isActive === 'my') {
        data = await this.$api.listInquiryOwner(searchCondition)

      } else if (this.isActive === 'myGroup') {
        data = await this.$api.ownerGroup(searchCondition)
      }
      list = data.list
      page_info = data.page_info

      let convertData = ['is_assign', 'is_platform_pay', 'is_send_back', 'is_cart', 'is_auth_price']
      list.forEach((item) => {
        convertData.forEach((i) => {
          if (i === 'is_assign' || i === 'is_send_back') {
            item[i] = item[i] === 1 ? '是' : '否'
          } else item[i] = item[i] === 2 ? '是' : '否'
        })
      })
      this.tableData = list
      this.tablePagination = page_info
    }
  },
  mounted() {
    this.getAllData()
  }
}
</script>

<style scoped>

</style>
